import React from "react"
import moment from "moment"
import _ from "lodash"

import { Badge } from ".."

export const PeriodEndStatusOrder = {
	NotYetOpen: 0,
	Open: 1,
	Closed: 2,
	Deleted: 3,
}

export const PeriodEndStatusColors = {
	NotYetOpen: "info",
	Open: "primary",
	Closed: "secondary",
	Deleted: "danger",
}

export const PeriodEndStatusColorValues = {
	NotYetOpen: "#33AE9A",
	Open: "#1EB7FF",
	Closed: "#868E96",
	Deleted: "#DC3545",
}

export const PeriodEndStatusNames = {
	NotYetOpen: "Not Yet Open",
	Open: "Open",
	Closed: "Closed",
	Deleted: "Deleted",
}

export const PeriodEndRagOrder = {
	NotDue: 0,
	Imminent: 1,
	OnHold: 2,
	OnTime: 3,
	Warning: 4,
	BehindSchedule: 5,
	Overdue: 6,
}
export const PeriodEndRagOk = {
	NotDue: true,
	Imminent: true,
	OnHold: true,
	OnTime: true,
	Warning: true,
	BehindSchedule: true,
	Overdue: false,
}
export const PeriodEndRagColors = {
	NotDue: "secondary",
	Imminent: "primary",
	OnHold: "info",
	OnTime: "success",
	Warning: "warning",
	BehindSchedule: "pink accent",
	Overdue: "danger",
}
export const PeriodEndRagColorValues = {
	NotDue: "#ADB7C3",
	Imminent: "#1EB7FF",
	OnHold: "#33AE9A",
	OnTime: "#1BB934",
	Warning: "#F27212",
	BehindSchedule: "#FF1493",
	Overdue: "#ED1C24",
}
export const PeriodEndRagNames = {
	NotDue: "Not Due",
	Imminent: "Imminent",
	OnHold: "On Hold",
	OnTime: "On Time",
	Warning: "Warning",
	BehindSchedule: "Behind",
	Overdue: "Overdue",
}

export function PeriodEndStatusBadge(periodEnd, content, options = { className: "badge-pill" }) {
	return (
		<Badge color={PeriodEndStatusColors[periodEnd.status]} title={PeriodEndStatusNames[periodEnd.status]} className={options.className}>
			{content || moment(periodEnd.endDate).format("DD/MM/YYYY")}
		</Badge>
	)
}
export function PeriodEndRagBadge(periodEnd, content, options = { className: "badge-pill" }) {
	return (
		<Badge color={PeriodEndRagColors[periodEnd.rag]} title={PeriodEndRagNames[periodEnd.rag]} className={options.className}>
			{content || moment(periodEnd.dueDate).format("DD/MM/YYYY")}
		</Badge>
	)
}

export function PeriodEndBadge(periodEnd, content, options = {}) {
	const color = periodEnd.status === "Closed" ? PeriodEndStatusColors[periodEnd.status] : PeriodEndRagColors[periodEnd.rag]
	return (
		<Badge color={color} title={PeriodEndRagNames[periodEnd.rag]} className={options.className}>
			{content || moment(periodEnd.dueDate).format("DD/MM/YYYY")}
		</Badge>
	)
}

const findClientForPeriodEnd = periodEnd => {
	let parent = periodEnd.property.parent
	while (parent.parent.type !== "Customer") {
		parent = parent.parent
	}
	return parent
}

const findTeamForPeriodEnd = (periodEnd, predicate) => {
	let parent = periodEnd.property
	let team = undefined

	do {
		team = parent.teams?.find(predicate)
		parent = parent.parent
	} while (parent.parent && !team?.users?.length)
	return team
}

export const PeriodEndMeta = periodEnd => ({
	YearMonth: moment(periodEnd.endDate).format("YYYY-MM"),

	ClientInHouseManagerName: _.find(findClientForPeriodEnd(periodEnd).teams, it => it.type === "InHouseAppointees" && it.name === "Other")?.users?.reduce(
		(prev, next) => (prev ? `${prev}, ${next.name}` : next.name),
		""
	),

	InHouseManagerName: findTeamForPeriodEnd(periodEnd, it => it.type === "InHouseAppointees" && it.name === "Other")?.users?.reduce(
		(prev, next) => (prev ? `${prev}, ${next.name}` : next.name),
		""
	),

	get Customer() {
		let parent = periodEnd.property.parent
		while (parent.type !== "Customer") {
			parent = parent.parent
		}
		return parent
	},

	Appointees: (type, teamName) => {
		let business = periodEnd.property
		let users = []
		do {
			users = business.teams?.find(it => it.type === type && it.name === teamName)?.users || []
			business = business.parent
		} while (!users.length && business) //  && (business.type !== "Customer"))

		if (!users.length) return "Unassigned"

		const res = users
			.map(it => it.name)
			.sort((a, b) => (a?.toUpperCase() > b?.toUpperCase() ? 1 : -1))
			.join(", ")

		return res
	},

	Client: findClientForPeriodEnd(periodEnd),
})
