import React from "react"
import { connect } from "react-redux"

import * as clearview from "../../../../components/@Clearview"
import ReportParamsModal from "./ReportParamsModal"
import PropTypes from "prop-types"

import { Badge, ListGroupItem, ListGroup, ExtendedDropdown } from "../../../../components"
import Dropdown from "react-bootstrap/Dropdown"
import * as ACTIONS from "../../../../store/actions"

class ReportsRunner extends React.Component {
	static propTypes = {
		reports: PropTypes.array.isRequired,
		doSubmitReport: PropTypes.func.isRequired,
	}

	constructor(props) {
		super(props)
		this.runReport = this.runReport.bind(this)
	}

	runReport(report, params) {
		const reportParam = {}

		Object.keys(params).forEach(key => {
			const prop = params[key]
			if (prop.id) reportParam[key] = prop.id
			else if (prop.value) reportParam[key] = prop.value
			else if (prop !== undefined) reportParam[key] = prop
		})
		this.props.doSubmitReport(report, reportParam)
	}

	render() {
		const reports = this.props.reports

		return (
			<Dropdown direction="down">
				<Dropdown.Toggle caret>Run new report</Dropdown.Toggle>
				<ExtendedDropdown persist>
					<ExtendedDropdown.Section list style={{ minHeight: 370 }}>
						<ListGroup flush outline className="hover">
							{reports.map(report => (
								<ListGroupItem key={report.key} id={`report_${report.key}`} title={`Click to run ${report.name} report.`} action>
									<h6>
										<Badge
											title={`health: ${report.health}, state: ${report.state}`}
											color={reportStatusColors[report.status]}
											className="badge-pill mr-2"
										>
											{report.status}
										</Badge>
										<b>{report.name}</b>
									</h6>
									{subTitle(report)}
									<ReportParamsModal
										target={`report_${report.key}`}
										title={report.name}
										subTitle={subTitle(report)}
										action={params => this.runReport(report, params)}
										disabledParams={report.disabledParams}
										defaultValues={report.defaultValues}
									/>
								</ListGroupItem>
							))}
						</ListGroup>
					</ExtendedDropdown.Section>
				</ExtendedDropdown>
			</Dropdown>
		)
	}
}

const reportStatusColors = {
	Idle: "success",
	Busy: "warning",
}

const subTitle = report => (
	<span className="small">
		last run: {clearview.FormatDateAndTime(report.latestRunTime)}, finished:{" "}
		{report.finishedTime != null ? clearview.FormatDateAndTime(report.finishedTime) : clearview.Icon.busy}
	</span>
)

const mapDispatchToProps = dispatch => ({
	doSubmitReport: (report, params) => {
		dispatch({ type: ACTIONS.REPORTS_REQUEST, payload: { report: report, params: params } })
	},
})

export default connect(null, mapDispatchToProps)(ReportsRunner)
