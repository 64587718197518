import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { Container, Row, Col } from "../../../components"

import { HeaderMain } from "../../components/HeaderMain"

import ReportsRunner from "./components/ReportsRunner"
import S3Navigator from "../components/s3/S3Navigator"

import * as ACTIONS from "../../../store/actions"
import * as clearview from "../../../components/@Clearview"
import { Spinner } from "../../components/Spinner"

class ReportsDashboard extends Component {
	static propTypes = {
		reports: PropTypes.array.isRequired,
		user: PropTypes.object.isRequired,
		business: PropTypes.object.isRequired,
		isBusy: PropTypes.bool.isRequired,
		lastUpdated: PropTypes.number,
		doRefresh: PropTypes.func.isRequired,
	}

	componentDidMount() {
		if (!this.props.reports || !this.props.reports.length) {
			this.props.doRefresh()
		}
	}

	render() {
		const { user, reports } = this.props

		let business = this.props.business
		let folder = `${business?.id}/${user.username}/reports`

		while (business?.parent && !["Root", "Customer"].includes(business.type)) {
			business = business.parent
			folder = `${business.id}/${folder}`
		}
		folder = `/user/${folder}`

		return (
			<Container className={this.props.isBusy ? "isBusy" : ""}>
				{this.props.isBusy && <Spinner key="spinner"></Spinner>}
				<HeaderMain
					title={<span className="text-primary">{clearview.Icon.reports}Reports</span>}
					className="mb-4 mt-0"
					onRefresh={() => this.props.doRefresh()}
				/>
				<Row>
					<Col lg={12} className="mb-2">
						You can create and download a detailed report. Each report can take a few minutes to produce. Check back here and click on{" "}
						<a
							href="#"
							className="clickable"
							onClick={() => {
								this.setState({ expandReports: true })
								this.props.doRefresh()
							}}
						>
							{clearview.Icon.refresh} Refresh
						</a>
						to check on progress.
						<ReportsRunner reports={reports} />
					</Col>
				</Row>
				<Row>
					<Col lg={12}>
						<Container style={{ height: "640px" }}>
							<S3Navigator key={folder} folder={folder} name="Reports" hideUpload={true}></S3Navigator>
						</Container>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = state => {
	const reportsSubState = ACTIONS.getSubState(state, "reportsReducer\\reports")

	const user = state.userReducer.user || clearview.User.UnauthenticatedUser
	const userOrg = user.teams?.find(it => it.type === "Users").business
	const business = ACTIONS.findInBranches(
		state.clientsReducer.clients.data,
		it => it.id === userOrg?.id,
		it => it.children
	)

	return {
		user: user,
		business: business,
		...reportsSubState,
		reports: ACTIONS.dictionaryToArray(reportsSubState.dict),
	}
}

const mapDispatchToProps = dispatch => ({
	doRefresh: () => {
		dispatch({ type: ACTIONS.REPORTS_ALL_FETCH, payload: {} })
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportsDashboard)
