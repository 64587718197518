/* eslint default-case: 0 */
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import _ from "lodash"

import PropTypes from "prop-types"

import { connect } from "react-redux"
import * as ACTIONS from "../store/actions"

import { Provider } from "../layout/context"

import * as clearview from "../components/@Clearview"

//------ Route Definitions --------
// eslint-disable-next-line no-unused-vars

const NoFilter = {
	isUnread: undefined,
	isAlert: undefined,
	client: undefined,
	property: undefined,
	templateFamily: undefined,
	template: undefined,
	phaseName: undefined,
	stageName: undefined,
	periodEndStatus: undefined,
	periodEndRag: undefined,
	periodEndRagOk: undefined,
	stageStatus: undefined,
	stageRag: undefined,
	stageRagOk: undefined,
	hasOpenQueries: undefined,
	inHouse: undefined,
	inHouseTeamMember: undefined,
	clientTeamMember: undefined,
	endDateRange: undefined,
	dueDateRange: undefined,
	clientInHouseManagerName: undefined,
	inHouseManagerName: undefined,
	yearMonth: undefined,
	appointees: undefined,
}

const DefaultFilter = {
	...NoFilter,
	periodEndStatus: { _title: "Open", value: "Open" },
}

const NoSort = {
	landlord: undefined,
	managingAgent: undefined,
	property: undefined,
	templateFamily: undefined,
	template: undefined,
	periodEndStatus: undefined,
	periodEndRag: undefined,
	stageStatus: undefined,
	stageRag: undefined,
	due: undefined,
}

const DefaultSort = {
	...NoSort,
}

function getInitialCustomer(customerTemplates) {
	if (!customerTemplates) return
	const customerRefs = Object.keys(customerTemplates)
	if (customerRefs.length === 1) return customerTemplates[customerRefs[0]]
}

class PreFilterContext extends React.Component {
	static propTypes = {
		children: PropTypes.node.isRequired,
		customerTemplates: PropTypes.object,
		user: PropTypes.object,
		clients: PropTypes.array,
		doRefresh: PropTypes.func.isRequired,
	}

	foundInTeams = (teams, user) => {
		return _.find(
			_.flatMap(
				teams.filter(it => true),
				it => it.users
			),
			it => it.username === user.username
		)
	}

	predicate = periodEnd => {
		const objFilters = this.parseObjectFilters()

		const apps = clearview.PeriodEndMeta(periodEnd).Appointees("InHouseAppointees", "Service Charge Manager")
		if (apps.includes("Ashley") && apps.includes("Sam")) {
			console.log(apps)
		}

		if (
			(objFilters.appointees === undefined ||
				objFilters.appointees.value === clearview.PeriodEndMeta(periodEnd).Appointees(objFilters.appointees.type, objFilters.appointees.teamName)) &&
			(this.state.filter.yearMonth === undefined ||
				this.state.filter.yearMonth === "undefined" ||
				this.state.filter.yearMonth === clearview.PeriodEndMeta(periodEnd).YearMonth) &&
			(this.state.filter.clientInHouseManagerName === undefined ||
				(this.state.filter.clientInHouseManagerName === "undefined" && !clearview.PeriodEndMeta(periodEnd).ClientInHouseManagerName) ||
				this.state.filter.clientInHouseManagerName === clearview.PeriodEndMeta(periodEnd).ClientInHouseManagerName) &&
			(this.state.filter.inHouseManagerName === undefined ||
				(this.state.filter.inHouseManagerName === "undefined" && !clearview.PeriodEndMeta(periodEnd).InHouseManagerName) ||
				this.state.filter.inHouseManagerName === clearview.PeriodEndMeta(periodEnd).InHouseManagerName) &&
			(this.state.filter.isUnread === undefined ||
				this.state.filter.isUnread === "undefined" ||
				(this.state.filter.isUnread === "unread" && periodEnd.isUnread) ||
				(this.state.filter.isUnread === "read" && !periodEnd.isUnread)) &&
			(this.state.filter.isAlert === undefined ||
				this.state.filter.isAlert === "undefined" ||
				(this.state.filter.isAlert === "alert" && periodEnd.isAlert) ||
				(this.state.filter.isAlert === "priority" && periodEnd.isFavourite)) &&
			(!this.state.filter.customer ||
				(periodEnd.property.lft >= this.state.filter.customer.lft && periodEnd.property.lft <= this.state.filter.customer.rgt)) &&
			(!this.state.filter.client || (periodEnd.property.lft >= this.state.filter.client.lft && periodEnd.property.lft <= this.state.filter.client.rgt)) &&
			(!this.state.filter.inHouseTeamMember || _.find(periodEnd.property.actors.inHouse, a => a.id === this.state.filter.inHouseTeamMember.id)) &&
			(!this.state.filter.clientTeamMember || _.find(periodEnd.property.actors.client, a => a.id === this.state.filter.clientTeamMember.id)) &&
			(!this.state.filter.property || periodEnd.property.id === this.state.filter.property.id) &&
			(!this.state.filter.templateFamily || periodEnd.template.family === this.state.filter.templateFamily.value) &&
			(!this.state.filter.template || periodEnd.template.id === this.state.filter.template.id) &&
			(!this.state.filter.phaseName ||
				(periodEnd.currentStage &&
					periodEnd.currentStage.templateStage.phase &&
					periodEnd.currentStage.templateStage.phase.name === this.state.filter.phaseName.value)) &&
			(!this.state.filter.stageName || (periodEnd.currentStage && periodEnd.currentStage.templateStage.name === this.state.filter.stageName.value)) &&
			(!this.state.filter.periodEndStatus || periodEnd.status === this.state.filter.periodEndStatus.value) &&
			(!this.state.filter.periodEndRag || periodEnd.rag === this.state.filter.periodEndRag.value) &&
			(this.state.filter.periodEndRagOk === undefined ||
				this.state.filter.periodEndRagOk === "undefined" ||
				(this.state.filter.periodEndRagOk === "true" && clearview.PeriodEndRagOk[periodEnd.rag] === true) ||
				(this.state.filter.periodEndRagOk === "false" && clearview.PeriodEndRagOk[periodEnd.rag] === false)) &&
			(!this.state.filter.stageStatus || (periodEnd.currentStage && periodEnd.currentStage.status === this.state.filter.stageStatus.value)) &&
			(!this.state.filter.stageRag || (periodEnd.currentStage && periodEnd.currentStage.rag === this.state.filter.stageRag.value)) &&
			(this.state.filter.stageRagOk === undefined ||
				this.state.filter.stageRagOk === "undefined" ||
				(this.state.filter.stageRagOk === "true" && periodEnd.currentStage && clearview.StageRagOk[periodEnd.currentStage.rag] === true) ||
				(this.state.filter.stageRagOk === "false" && periodEnd.currentStage && clearview.StageRagOk[periodEnd.currentStage.rag] === false)) &&
			(this.state.filter.hasOpenQueries === undefined ||
				this.state.filter.hasOpenQueries === "undefined" ||
				(this.state.filter.hasOpenQueries === "true" && periodEnd.openQueries.length > 0) ||
				(this.state.filter.hasOpenQueries === "false" && periodEnd.openQueries.length === 0)) &&
			(this.state.filter.inHouse === undefined ||
				this.state.filter.inHouse === "undefined" ||
				(this.state.filter.inHouse === "InHouse" && periodEnd.currentStage && periodEnd.currentStage.templateStage.isInHouse === true) ||
				(this.state.filter.inHouse === "WithClient" && periodEnd.currentStage && periodEnd.currentStage.templateStage.isInHouse === false)) &&
			(this.state.filter.endDate === undefined ||
				this.state.filter.endDate === "undefined" ||
				!this.state.filter.endDate ||
				(periodEnd.endDate >= this.state.filter.endDate[0].toISOString().substring(0, 19) &&
					periodEnd.endDate <= this.state.filter.endDate[1].toISOString().substring(0, 19))) &&
			(this.state.filter.dueDate === undefined ||
				this.state.filter.dueDate === "undefined" ||
				!this.state.filter.dueDate ||
				(periodEnd.dueDate >= this.state.filter.dueDate[0].toISOString().substring(0, 19) &&
					periodEnd.dueDate <= this.state.filter.dueDate[1].toISOString().substring(0, 19)))
		)
			return true

		return false
	}

	sortPredicate = periodEnd => {
		return Object.keys(this.state.sort).reduce((acc, key) => {
			const value = this.state.sort[key]
			if (["asc", "desc"].includes(value)) {
				switch (key) {
					case "managingAgent":
						return periodEnd.property.parent.parent ? periodEnd.property.parent.parent.reference : null

					case "landlord":
						return periodEnd.property.parent.reference

					case "property":
						return periodEnd.property.reference

					case "templateFamily":
						return periodEnd.template.family

					case "template":
						return periodEnd.template.reference

					case "periodEndStatus":
						return clearview.PeriodEndStatusOrder[periodEnd.status] * (value === "desc" ? -1 : 1)

					case "periodEndRag":
						return clearview.PeriodEndStatusOrder[periodEnd.rag] * (value === "desc" ? -1 : 1)

					case "stageStatus":
						return periodEnd.currentStage ? clearview.StageStatusOrder[periodEnd.currentStage.status] * (value === "desc" ? -1 : 1) : null

					case "stageRag":
						return periodEnd.currentStage ? clearview.StageRagOrder[periodEnd.currentStage.rag] * (value === "desc" ? -1 : 1) : null

					case "due":
						return periodEnd.currentStage ? new Date(periodEnd.currentStage.dueDate).getTime() * (value === "desc" ? -1 : 1) : null
				}
			}

			return acc
		}, null)
	}

	parseObjectFilters = () => {
		return {
			appointees:
				this.state.filter?.appointees === undefined || this.state.filter.appointees === "undefined"
					? undefined
					: JSON.parse(this.state.filter.appointees),
		}
	}

	checkIfPreFilterLinked = () => {
		const preFilterState = useLocation().state
		if (preFilterState && preFilterState.to) {
			const parts = preFilterState.to.split("?")
			const queryObject = clearview.QueryObject(parts[1])
			this.setPreFilter(preFilterState.periodEnds, queryObject, preFilterState.clear)

			delete useLocation().state.to // Only apply the To prefilter when first arriving on page
			useNavigate()(parts[0], {})
		}
	}

	setPreFilter = (periodEnds, preFilter, clear) => {
		if (clear) {
			this.state.filter = { ...this.state.filter, ...NoFilter } // This should preserve the customer filter
		}
		if (preFilter) {
			const periodEndStatuses = Object.keys(clearview.PeriodEndStatusNames).map(key => ({ value: key, _title: clearview.PeriodEndStatusNames[key] }))
			const periodEndRags = Object.keys(clearview.PeriodEndRagNames).map(key => ({ value: key, _title: clearview.PeriodEndRagNames[key] }))
			const stageStatuses = Object.keys(clearview.StageStatusNames).map(key => ({ value: key, _title: clearview.StageStatusNames[key] }))
			const stageRags = Object.keys(clearview.StageRagNames).map(key => ({ value: key, _title: clearview.StageRagNames[key] }))

			const clients = _.sortBy(
				_.uniqBy(
					periodEnds
						.map(it => it.property.parent)
						.concat(periodEnds.map(it => it.property.parent.parent))
						.filter(it => it),
					it => it.id
				).map(it => ({ ...it, _title: clearview.GetBusinessTitle(it) })),
				it => it._title
			)
			const properties = _.sortBy(
				_.uniqBy(
					periodEnds.map(it => it.property),
					it => it.id
				).map(it => ({ ...it, _title: clearview.GetBusinessTitle(it) })),
				it => it._title
			)
			const templateFamilies = _.sortBy(
				_.uniqBy(
					periodEnds.map(it => it.template),
					it => it.family
				).map(it => ({ value: it.family, _title: `${it.family}` })),
				it => it._title
			)
			const templates = _.sortBy(
				_.uniqBy(
					periodEnds.map(it => it.template),
					it => it.id
				).map(it => ({ ...it, _title: `${it.reference}: ${it.name}` })),
				it => it.reference
			)
			const phaseNames = _.sortBy(
				_.uniqBy(
					periodEnds
						.filter(it => it.currentStage && it.currentStage.templateStage.phase)
						.map(pe => ({ value: pe.currentStage.templateStage.phase.name, _title: pe.currentStage.templateStage.phase.name })),
					it => it.value
				),
				it => it._title
			)
			const stageNames = _.sortBy(
				_.uniqBy(
					periodEnds
						.filter(it => it.currentStage)
						.map(pe => ({ value: pe.currentStage.templateStage.name, _title: pe.currentStage.templateStage.name })),
					it => it.value
				),
				it => it._title
			)
			const inHouseTeamMembers = _.sortBy(
				_.uniqBy(
					_.flatMap(periodEnds, it => it.property.actors.inHouse),
					"username"
				),
				it => it.Name
			)
			const clientTeamMembers = _.sortBy(
				_.uniqBy(
					_.flatMap(periodEnds, it => it.property.actors.client),
					"username"
				),
				it => it.Name
			)

			Object.keys(preFilter).forEach(key => {
				switch (key) {
					case "isUnread":
						this.state.filter[key] = preFilter[key]
						break
					case "isAlert":
						this.state.filter[key] = preFilter[key]
						break

					case "customer":
						this.state.filter[key] = this.props.customerTemplates[key]
						break
					case "client":
						this.state.filter[key] = _.find(clients, it => it.reference === preFilter[key])
						break
					case "property":
						this.state.filter[key] = _.find(properties, it => it.reference === preFilter[key])
						break
					case "templateFamily":
						this.state.filter[key] = _.find(templateFamilies, it => it.value === preFilter[key])
						break
					case "template":
						this.state.filter[key] = _.find(templates, it => it.reference === preFilter[key])
						break
					case "phaseName":
						this.state.filter[key] = _.find(phaseNames, it => it.value === preFilter[key])
						break
					case "stageName":
						this.state.filter[key] = _.find(stageNames, it => it.value === preFilter[key])
						break

					case "periodEndStatus":
						this.state.filter[key] = _.find(periodEndStatuses, it => it.value === preFilter[key])
						break
					case "periodEndRag":
						this.state.filter[key] = _.find(periodEndRags, it => it.value === preFilter[key])
						break
					case "periodEndRagOk":
						this.state.filter[key] = preFilter[key]
						break
					case "stageStatus":
						this.state.filter[key] = _.find(stageStatuses, it => it.value === preFilter[key])
						break
					case "stageRag":
						this.state.filter[key] = _.find(stageRags, it => it.value === preFilter[key])
						break
					case "stageRagOk":
						this.state.filter[key] = preFilter[key]
						break
					case "hasOpenQueries":
						this.state.filter[key] = preFilter[key]
						break
					case "inHouse":
						this.state.filter[key] = preFilter[key]
						break

					case "inHouseTeamMember":
						this.state.filter[key] = _.find(inHouseTeamMembers, it => it.username === preFilter[key])
						break
					case "clientTeamMember":
						this.state.filter[key] = _.find(clientTeamMembers, it => it.username === preFilter[key])
						break

					case "endDateRange":
						this.state.filter[key] = preFilter[key]
						break
					case "dueDateRange":
						this.state.filter[key] = preFilter[key]
						break

					default:
						if (key in this.state.filter) this.state.filter[key] = preFilter[key]
						break
				}
			})
			preFilter = null
		}
		return true
	}

	filtered = periodEnds => periodEnds.filter(it => this.predicate(it))
	sortedAndFiltered = periodEnds => _.sortBy(this.filtered(periodEnds), [pe => this.sortPredicate(pe)])

	criteriaCount() {
		return Object.keys(this.state.filter).reduce((acc, key) => (this.state.filter[key] ? acc + 1 : acc), 0)
	}

	sortCriteriaCount() {
		return Object.keys(this.state.sort).reduce((acc, key) => (this.state.sort[key] ? acc + 1 : acc), 0)
	}

	handleChange(option, type) {
		this.setState({
			filter: {
				...this.state.filter,
				[type]: _.first(option),
			},
		})
	}

	handleRadioChange(event, type) {
		this.setState({
			filter: {
				...this.state.filter,
				[type]: event.target.value,
			},
		})
	}

	handleRemoveOtherFilter(event) {
		this.setState({
			filter: {
				...this.state.filter,
				[event.target.name]: undefined,
			},
		})
	}

	handleDateRangeChange(value, type) {
		this.setState({
			filter: {
				...this.state.filter,
				[type]: value,
			},
		})
	}

	handleSortToggleChange(event, type) {
		this.setState({
			sort: {
				...NoSort,
				[type]: event.target.value,
			},
		})
	}

	clearFilters(namedFilter = "NoFilter") {
		const NamedFilters = {
			PeriodEndUnread: {
				...NoFilter,
				isUnread: "unread",
			},
			PeriodEndFilterStatusOpen: {
				...NoFilter,
				periodEndStatus: { _title: "Open", value: "Open" },
			},
			NoFilter: {
				...NoFilter,
			},
		}

		this.setState(prevState => ({
			filter: { ...prevState.filter, ...NamedFilters[namedFilter] }, // This should preserve the customer filter
		}))
	}

	clearSort(event, type) {
		this.setState({
			sort: { ...NoSort },
		})
	}

	findBusiness = predicate => ACTIONS.findInBranches(this.props.clients, predicate, it => it.children)

	constructor(props) {
		super(props)

		this.state = {
			filter: {},
			sort: {},
		}

		this.handleChange = this.handleChange.bind(this)
		this.handleRadioChange = this.handleRadioChange.bind(this)
		this.handleDateRangeChange = this.handleDateRangeChange.bind(this)
		this.handleSortToggleChange = this.handleSortToggleChange.bind(this)
		this.handleRemoveOtherFilter = this.handleRemoveOtherFilter.bind(this)

		this.clearFilters = this.clearFilters.bind(this)
		this.clearSort = this.clearSort.bind(this)
		this.criteriaCount = this.criteriaCount.bind(this)
		this.sortCriteriaCount = this.sortCriteriaCount.bind(this)

		this.predicate = this.predicate.bind(this)
		this.sortPredicate = this.sortPredicate.bind(this)

		this.parseObjectFilters = this.parseObjectFilters.bind(this)
		this.checkIfPreFilterLinked = this.checkIfPreFilterLinked.bind(this)
		this.setPreFilter = this.setPreFilter.bind(this)
		this.filtered = this.filtered.bind(this)
		this.sortedAndFiltered = this.sortedAndFiltered.bind(this)

		this.findBusiness = this.findBusiness.bind(this)
	}

	componentDidMount() {
		if (!this.props.user) {
			this.props.doRefresh()
		}
	}

	static getDerivedStateFromProps(props, state) {
		if (props.customerTemplates && !state.filter.customer && getInitialCustomer(props.customerTemplates)) {
			return {
				filter: {
					...DefaultFilter,
					...state.filter,
					customer: getInitialCustomer(props.customerTemplates),
				},
				sort: { ...DefaultSort },
			}
		}
		return null
	}

	render() {
		const { children } = this.props

		return (
			<Provider
				value={{
					user: this.props.user,
					state: this.state,
					handleChange: this.handleChange,
					handleRadioChange: this.handleRadioChange,
					handleDateRangeChange: this.handleDateRangeChange,
					handleSortToggleChange: this.handleSortToggleChange,
					handleRemoveOtherFilter: this.handleRemoveOtherFilter,
					clearFilters: this.clearFilters,
					clearSort: this.clearSort,
					criteriaCount: this.criteriaCount,
					sortCriteriaCount: this.sortCriteriaCount,
					parseObjectFilters: this.parseObjectFilters,
					checkIfPreFilterLinked: this.checkIfPreFilterLinked,
					setPreFilter: this.setPreFilter,
					filtered: this.filtered,
					sortedAndFiltered: this.sortedAndFiltered,
					findBusiness: this.findBusiness,
				}}
			>
				{children}
			</Provider>
		)
	}
}

const mapStateToProps = state => {
	const customerTemplates = ACTIONS.getSubState(state, "clientsReducer\\templates")
	return {
		user: state.userReducer.user,
		clients: state.clientsReducer.clients.data,
		customerTemplates: customerTemplates.dict,
	}
}

const mapDispatchToProps = dispatch => ({
	doRefresh: () => {
		dispatch({ type: ACTIONS.USER_AUTHENTICATED, payload: {} })
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(PreFilterContext)
